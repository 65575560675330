import "./Main.css";
import React from "react";
import Promo from "../Promo/Promo";
import Directions from "../Directions/Directions";
import Hall from "../Hall/Hall";
import Scheme from "../Scheme/Scheme";
import Team from "../Team/Team";
import Price from "../Price/Price";

import {
  works,
  realizedWorks,
  advantages,
  questions,
} from "../../utils/constants";
import Questions from "../Questions/Questions";
import Quiz from "../Quiz/Quiz";
import Feedback from "../Feedback/Feedback";
import manuscriptImage from "../../images/Object/manuscript.svg";
import Advantages from "../Advantages/Advantages";
import LoadableImage from "../LoadableImage/LoadableImage";

import { Helmet } from "react-helmet";

const Main = ({ handleOpenPopup, handleOpenPopupRequest }) => {
  return (
    <main className="main">
      <Helmet>
        <title>
          Заказать дизайн проект интерьера в СПБ – студия дизайна СОДА
        </title>
        <meta
          name="description"
          content="Студия дизайна СОДА воплощает авторские, продуманные до мелочей, дизайн проекты интерьера в Санкт-Петербурге СОДА. Заказывая дизайн интерьера у нас, вы получите полную с 3D-визуализацию и рабочую документацию по проекту."
        />
      </Helmet>
      <Promo
        title={"Готовые интерьеры под ключ"}
        handleOpenPopup={handleOpenPopup}
      />
      <LoadableImage
        src={manuscriptImage}
        className="main__manuscript"
        alt="Рукопись «Ваше новое качество жизни»"
      />

      <Directions />
      <Hall
        title={"Галерея работ"}
        projects={works}
        buttonTitle={"Посмотреть все работы"}
      />
      <div className="main__about">
        <h2 className="main__title">О нас</h2>
        <p className="main__text">
          Студия дизайна интерьеров СОДА – это профессиональная компания,
          специализирующаяся на создании уникальных и функционально продуманных
          проектов для квартир, домов и коммерческих помещений в
          Санкт-Петербурге. Мы фокусируемся на том, чтобы каждое пространство
          было не только красивым, но и удобным для жизни, идеально отвечая
          вашим запросам и стилю. 
        </p>
        <p className="main__text">
          Мы понимаем, что интерьер – это отражение личности клиента. Поэтому
          каждый наш дизайн проект – это индивидуальный подход к архитектуре
          пространства, пожеланиям клиента и деталям реализации. Мы предлагаем
          полный цикл услуг: от разработки дизайна до помощи в строительстве,
          авторского надзора, комплектации и полной сдачи объекта “под ключ”.
          Благодаря профессиональной 3D-визуализации вы сможете заранее увидеть,
          каким будет ваш будущий интерьер в мельчайших подробностях – это
          исключает любые недоразумения и позволяет на этапе проектирования
          согласовать все детали.  
        </p>
        <p className="main__text">
          В нашей студии вы получите не только высококачественное выполнение
          работы, но и четкое соблюдение сроков и бюджета. Мы работаем с
          современными технологиями, трендовыми материалами и сотрудничаем с
          проверенными поставщиками мебели и декора. С вами будет работать
          команда опытных дизайнеров, архитекторов, проект-менеджеров и бригад
          строителей, что позволяет полностью контролировать процесс реализации
          и гарантировать отличный результат. 
        </p>
      </div>
      <Scheme />
      <Hall
        title={"Реализованные проекты"}
        projects={realizedWorks}
        text={true}
        buttonTitle={"Записаться на экскурсию на живой объект"}
        handleOpenPopupRequest={handleOpenPopupRequest}
      />
      <Team />
      <Advantages advantages={advantages} />
      <Price />
      <Feedback />
      <Questions questions={questions} />
      <Quiz handleOpenPopupRequest={handleOpenPopupRequest} />
    </main>
  );
};

export default Main;
