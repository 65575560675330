import React from "react";
import { hydrate, render } from "react-dom";
import App from "./components/App/App.js";
import { BrowserRouter } from "react-router-dom"; 

const rootElement = document.getElementById("root");

document.addEventListener("DOMContentLoaded", () => {
  const root = document.getElementById("root");

  if (root) {
    // Удаляем все, кроме root в body
    [...document.body.children].forEach((el) => {
      if (el !== root) el.remove();
    });

    // Оставляем внутри root только div с классом "root"
    [...root.children].forEach((el) => {
      if (!el.classList.contains("root")) el.remove();
    });
  }
});


if (rootElement.hasChildNodes()) {
  hydrate(<React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>, rootElement);
} else {
  render(<React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>, rootElement);
}
