import "./Aparts.css";
import React, { useState, useRef } from "react";
import Promo from "../Promo/Promo";
import Scheme from "../Scheme/Scheme";
import Price from "../Price/Price";
import Gallery from "../Gallery/Gallery";
import { galleryAparts } from "../../utils/gallery";

import Questions from "../Questions/Questions";
import Quiz from "../Quiz/Quiz";
import Feedback from "../Feedback/Feedback";
import manuscriptImage from "../../images/Object/manuscript.svg";
import Advantages from "../Advantages/Advantages";
import LoadableImage from "../LoadableImage/LoadableImage";
import CustomVideoPlayer from "../CustomVideoPlayer/CustomVideoPlayer";

import openIcon from "../../images/Main/union.svg";
import Slider from "react-slick";

import useWindowSize from "../../hooks/useWindowSize";

import video1 from "../../images/RealizedGallery/leontievsky/video.mp4";
import poster1 from "../../images/RealizedGallery/leontievsky/poster.png";

import video2 from "../../images/RealizedGallery/mirozdanie/video.mp4";
import poster2 from "../../images/RealizedGallery/mirozdanie/poster.png";

import arrow from "../../images/Main/arrow.svg";
import {
  advantages,
  designPlans,
  factors,
  questionsAparts,
  sliderImages,
} from "../../utils/constants";

import { Accordion, Panel } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";

import { Helmet } from "react-helmet";

const Aparts = ({ handleOpenPopup, handleOpenPopupRequest }) => {
  const [activeDesignElement, setActiveDesignElement] = useState(0);
  const [currentSliderImages, setCurrentSliderImages] = useState(
    sliderImages.first
  );
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentSlideIndexes, setCurrentSlideIndexes] = useState([0, 0, 0, 0]);
  const [activeKey, setActiveKey] = useState(null);
  const [activeKeySecond, setActiveKeySecond] = useState(0);
  const [isSliderVisible, setIsSliderVisible] = useState(true);

  const size = useWindowSize();

  const sliderRef = useRef(null);
  const sliderRefSecond = useRef(null);
  const designContainerRef = useRef(null);

  const handleSelect = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const handleSelectSecond = (key) => {
    setActiveKeySecond(activeKeySecond === key ? null : key);
  };

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button
        className={`design__slider-button design__slider-button--prev`}
        onClick={onClick}
      >
        <img src={arrow} className="popup-image__arrow" alt="стрелка влево" />
      </button>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button
        className={`design__slider-button design__slider-button--next`}
        onClick={onClick}
      >
        <img src={arrow} className="popup-image__arrow" alt="стрелка вправо" />
      </button>
    );
  };

  let settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    arrows: true,
    focusOnSelect: true,
    centerPadding: "0",
    speed: 300,
    swipeToSlide: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => setCurrentSlideIndex(next),
  };

  let settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    arrows: true,
    focusOnSelect: true,
    centerPadding: "0",
    swipeToSlide: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => handleSlideChange(activeKeySecond, next),
  };

  const handleDesignElementClick = (index) => {
    if (index === activeDesignElement) return;
    setActiveDesignElement(index);
    setIsSliderVisible(false);

    setTimeout(() => {
      switch (index) {
        case 0:
          setCurrentSliderImages(sliderImages.first);
          break;
        case 1:
          setCurrentSliderImages(sliderImages.second);
          break;
        case 2:
          setCurrentSliderImages(sliderImages.third);
          break;
        case 3:
          setCurrentSliderImages(sliderImages.fourth);
          break;
        default:
          setCurrentSliderImages(sliderImages.first);
      }

      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
      setTimeout(() => {
        setIsSliderVisible(true);
      }, 300);
    }, 300);
  };

  const handleSlideChange = (index, slideIndex) => {
    setCurrentSlideIndexes((prev) => {
      const newIndexes = [...prev];
      newIndexes[index] = slideIndex;
      return newIndexes;
    });
  };

  return (
    <section className="aparts">
      <Helmet>
        <title>
          Заказать дизайн проект интерьера квартиры с 3D-визуализацией в СПБ –
          СОДА
        </title>
        <meta
          name="description"
          content="Студия СОДА воплощает авторские, продуманные до мелочей, дизайн проекты интерьера квартир в Санкт-Петербурге СОДА. Заказывая дизайн интерьера квартиры у нас, вы получите полную с 3D-визуализацию и рабочую документацию по проекту."
        />
      </Helmet>
      <Promo
        title={"Дизайн интерьера квартиры “под ключ”"}
        handleOpenPopup={handleOpenPopup}
      />
      <LoadableImage
        src={manuscriptImage}
        className="main__manuscript"
        alt="Рукопись «Ваше новое качество жизни»"
      />
      <div className="main__about main__about_type_aparts">
        <p className="main__text">
          Дизайн интерьера – это не просто разработка красивого облика вашего
          жилья, но и создание функционального пространства, в котором каждая
          деталь учитывается в соответствии с вашими предпочтениями и образом
          жизни. Студия СОДА предлагает услугу дизайн интерьера квартиры под
          ключ, чтобы вы могли с легкостью воплотить свою мечту об идеальном
          жилье. Мы сопровождаем весь процесс: от концепции и планирования до
          окончательной реализации и расстановки мебели.
        </p>
        <p className="main__text">
          Доверяя нам, вы не только экономите время и силы, но и получаете
          уникальный и продуманный проект, в котором гармонично соединены стиль,
          удобство и индивидуальность.
        </p>
      </div>
      <div className="service__question">
        <h2 className="service__question-title">Стоимость услуг</h2>
        <Accordion activeKey={activeKey} onSelect={handleSelect}>
          <Panel
            eventKey="0"
            className="questions__container"
            header={
              <div className="questions__title-container">
                <h3 className="question__question">
                  Дизайн-проект <br className="prices__wrap" /> от 5 000 руб. за
                  м²
                </h3>
                <button
                  type="button"
                  className={`button questions__button ${
                    activeKey === "0" ? "questions__button_active" : ""
                  }`}
                >
                  <img
                    src={openIcon}
                    className={`question__open-image ${
                      activeKey === "0" ? "questions__open-image_active" : ""
                    }`}
                    alt="Значок вверх"
                  />
                </button>
              </div>
            }
          >
            <div className="questions__answer questions__answer_active">
              <div className="service__answer">
                <p className="servise__answer-title">
                  Стоимость дизайн проекта квартиры формируется исходя из набора
                  этапов, которые включены в одну комплексную услугу. Каждый
                  этап разрабатывается профессионалами для создания идеального
                  дизайна интерьера:
                </p>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Анализ объекта</li>
                  <li className="policy__element servise__answer-text">
                    Мы изучаем особенности помещения, его размеры, освещение и
                    архитектурные детали. Это помогает понять возможности
                    пространства и найти лучшие решения для дизайна интерьера.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Обмерный план</li>
                  <li className="policy__element servise__answer-text">
                    Разработка точного обмерного плана квартиры, который
                    включает замеры всех помещений, а также расположение окон,
                    дверей и других конструкций.  
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Техническое задание</li>
                  <li className="policy__element servise__answer-text">
                    Мы составляем список пожеланий и требований клиента — от
                    стилистики до конкретных видов отделочных материалов, мебели
                    и элементов декора.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Планировочное решение
                  </li>
                  <li className="policy__element servise__answer-text">
                    Создание планировки помещений с оптимальным зонированием,
                    где каждый метр используется эффективно и с максимальной
                    пользой.  
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Согласование бюджета
                  </li>
                  <li className="policy__element servise__answer-text">
                    Общий расчет стоимости реализации проекта с учетом стоимости
                    отделочных материалов, мебели и работ. Это поможет избежать
                    лишних затрат. 
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">3D-визуализация</li>
                  <li className="policy__element servise__answer-text">
                    Фотореалистичные визуализации будущих интерьеров помогут
                    увидеть результат еще до начала ремонта, представить детали
                    и внести коррективы.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Комплектация проекта
                  </li>
                  <li className="policy__element servise__answer-text">
                    Подбор мебели, декора, отделочных материалов, освещения и
                    других предметов интерьера, необходимых для реализации. Мы
                    создаем списки с указанием точных позиций, брендов и
                    стоимости каждого элемента.  
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Рабочая документация для строителей
                  </li>
                  <li className="policy__element servise__answer-text">
                    Подробные чертежи и планы, необходимые для корректного
                    выполнения строительно-ремонтных работ. Они включают схемы
                    электрооборудования, разводки сантехники, разрезы и
                    рекомендации по использованию материалов.
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Accordion>
        <button
          className="button"
          style={{ alignSelf: "center" }}
          onClick={handleOpenPopup}
        >
          Заказать проект
        </button>
      </div>

      <Gallery gallery={galleryAparts} small={true} />
      <div className="object__container">
        <h2 className="object__title object__title_type_video">
          Видеообзоры выполненных объектов
        </h2>
        <div className="service__videos">
          <CustomVideoPlayer videoSrc={video1} poster={poster1} />
          <CustomVideoPlayer videoSrc={video2} poster={poster2} />
        </div>
        <a
          className="button gallery__link"
          href="https://vk.com/video/@penta_pro"
          target="_blank"
          rel="noreferrer"
        >
          Посмотреть другие видеообзоры
        </a>
      </div>
      <Scheme />
      <div className="design">
        <h2 className="design__title">Дизайн-проект</h2>
        <p className="design__text">
          Вы получите персональный проект интерьера со всей рабочей
          документацией
        </p>
        {size.width > 576 && (
          <div ref={designContainerRef} className="design__container">
            <div className="design__column">
              <div
                className={`design__element ${
                  activeDesignElement === 0 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(0)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 0 ? "design__number_active" : ""
                  }`}
                >
                  1
                </p>
                Планировочное решение
              </div>
              <div
                className={`design__element ${
                  activeDesignElement === 1 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(1)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 1 ? "design__number_active" : ""
                  }`}
                >
                  2
                </p>
                Технические чертежи
              </div>
              <div
                className={`design__element ${
                  activeDesignElement === 2 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(2)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 2 ? "design__number_active" : ""
                  }`}
                >
                  3
                </p>
                3D-визуализация
              </div>
              <div
                className={`design__element ${
                  activeDesignElement === 3 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(3)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 3 ? "design__number_active" : ""
                  }`}
                >
                  4
                </p>
                Спецификация
              </div>
            </div>
            <div
              className={`design__slider ${
                isSliderVisible ? "visible" : "hidden"
              }`}
            >
              <Slider className="plans" {...settings} ref={sliderRef}>
                {currentSliderImages.map((image) => (
                  <LoadableImage
                    src={image}
                    className="design__image"
                    alt="Схема"
                  />
                ))}
              </Slider>
              <p className="design__slider-index">
                {currentSlideIndex + 1}/{currentSliderImages.length}
              </p>
            </div>
          </div>
        )}
        {size.width < 576 && (
          <Accordion activeKey={activeKeySecond} onSelect={handleSelectSecond}>
            {designPlans.map((data, index) => (
              <Panel
                key={index}
                eventKey={index}
                header={
                  <div
                    className={`design__element ${
                      activeKeySecond === index ? "design__element_active" : ""
                    }`}
                  >
                    <p
                      className={`design__number ${
                        activeKeySecond === index ? "design__number_active" : ""
                      }`}
                    >
                      {data.number}
                    </p>
                    {data.title}
                    <button
                      type="button"
                      className={`button questions__button ${
                        activeKeySecond === index
                          ? "questions__button_active"
                          : ""
                      }`}
                    >
                      <img
                        src={openIcon}
                        className={`question__open-image ${
                          activeKeySecond === index
                            ? "questions__open-image_active"
                            : ""
                        }`}
                        alt="Значок вверх"
                      />
                    </button>
                  </div>
                }
              >
                <div className={`design__slider`}>
                  <Slider
                    className="plans"
                    {...settings2}
                    ref={sliderRefSecond}
                  >
                    {data.plans.map((image) => (
                      <LoadableImage
                        src={image}
                        className="design__image"
                        alt="Схема"
                      />
                    ))}
                  </Slider>
                  <p className="design__slider-index">
                    {currentSlideIndexes[activeKeySecond] + 1}/
                    {data.plans.length}
                  </p>
                </div>
              </Panel>
            ))}
          </Accordion>
        )}
      </div>
      <div className="factors">
        <h3 className="factors__title">
          9 факторов, от которых зависит цена на дизайн квартиры
        </h3>
        <ul className="factors__list">
          {factors.map((element) => (
            <li className="factors__element" key={element.number}>
              <span className="factors__number">{element.number}</span>
              {element.factor}
            </li>
          ))}
        </ul>
      </div>
      <div className="mon">
        <h2 className="service__question-title">Стили дизайна интерьера </h2>
        <div className="mon__container">
          <h4 className="mon__subtitle">Современный стиль</h4>
          <p className="mon__text">
            Современный дизайн интерьера идеально подходит для тех, кто ценит
            минимализм и удобство. В интерьере царит гармония простоты и
            функциональности. Преобладают лаконичные линии, нейтральные оттенки
            (белый, серый, бежевый), а среди материалов используются стекло,
            металл и дерево. Он отлично подходит для небольших квартир и активно
            используется в планировках открытого типа.
          </p>
          <p className="mon__text">Особенности:</p>
          <ul className="mon__list">
            <li className="mon__point">Функциональная мебель простых форм;</li>
            <li className="mon__point">Однородность отделочных материалов;</li>
            <li className="mon__point">
              Много света и простор в помещениях;  
            </li>
            <li className="mon__point">
              Минимум декора, максимум практичности.
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Скандинавский стиль</h4>
          <p className="mon__text">
            Он захватил сердца многих своей уютной простой элегантностью. Стиль
            идеально подходит для тех, кто хочет создать светлый и воздушный
            дизайн интерьера квартиры. В нем используются натуральные материалы,
            акцентные цветные детали вроде подушек или декора, а также
            добавление живых растений. 
          </p>
          <p className="mon__text">Особенности:</p>
          <ul className="mon__list">
            <li className="mon__point">
              Светлая отделка стен и полов (часто белый, светло-серый, древесный
              оттенок);
            </li>
            <li className="mon__point">
              Простая, но уютная мебель, выполненная из натуральных материалов;
            </li>
            <li className="mon__point">
              Декор из текстиля – пледы, подушки, ковры;
            </li>
            <li className="mon__point">
              Внимание к естественному и искусственному освещению.  
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Лофт</h4>
          <p className="mon__text">
            Это дерзкий и стильный интерьер, привлекающий внимание брутальностью
            и оригинальностью. Он родом из индустриальных зданий,
            переоборудованных под жилье, и идеально подойдет тем, кто любит
            экспериментировать. Такой дизайн интерьера часто выбирают для
            просторных квартир или квартир-студий, где можно выделить открытые
            зоны без привычных стен. 
          </p>
          <p className="mon__text">Особенности:</p>
          <ul className="mon__list">
            <li className="mon__point">
              Обилие открытого пространства и отсутствие перегородок;
            </li>
            <li className="mon__point">
              Насыщенное использование грубых отделочных материалов (кирпич,
              бетон);
            </li>
            <li className="mon__point">
              Сочетание современной мебели и стиля винтаж;
            </li>
            <li className="mon__point">
              Акцент на оригинальное освещение (индустриальные светильники,
              лампы Эдисона).
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Классический стиль</h4>
          <p className="mon__text">
            Это символ элегантности и гармонии. Он подходит для тех, кто ценит
            роскошь, изысканность и спокойствие. Этот стиль активно использует
            натуральные материалы: дорогая мебель, выполненная из массива
            дерева, мраморные столешницы, тканые ковры. 
          </p>
          <p className="mon__text">Особенности:</p>
          <ul className="mon__list">
            <li className="mon__point">
              Гладкая и симметричная мебель из дорогих материалов;
            </li>
            <li className="mon__point">
              Элементы роскоши: хрустальные люстры, карнизы, антиквариат;
            </li>
            <li className="mon__point">
              Теплая цветовая гамма (беж, песочный, золото, бордо);
            </li>
            <li className="mon__point">
              Детальная проработка всех эстетов пропорций
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Минимализм</h4>
          <p className="mon__text">
            Это выбор тех, кто стремится к чистоте и упорядоченности интерьера.
            В этом стиле нет места лишним деталям – каждая вещь в доме должна
            быть функциональной. Минималистичный дизайн интерьера квартиры
            подходит для современных ритмов жизни, где многое автоматизировано,
            а детали сведены к минимуму.
          </p>
          <p className="mon__text">Особенности:</p>
          <ul className="mon__list">
            <li className="mon__point">
              Однотонные поверхности и простые формы;
            </li>
            <li className="mon__point">Минимальный подбор мебели и декора;</li>
            <li className="mon__point">
              Много свободного пространства, отсутствие громоздких вещей;
            </li>
            <li className="mon__point">
              Использование современной техники и систем умного дома.
            </li>
          </ul>
        </div>
      </div>
      <Advantages advantages={advantages} />
      <Price />
      <Feedback />
      <Questions questions={questionsAparts} />
      <Quiz handleOpenPopupRequest={handleOpenPopupRequest} />
    </section>
  );
};

export default Aparts;
