import "../Aparts/Aparts.css";
import React, { useState, useRef } from "react";
import Promo from "../Promo/Promo";
import Scheme from "../Scheme/Scheme";
import Price from "../Price/Price";
import Gallery from "../Gallery/Gallery";
import { galleryHouses } from "../../utils/gallery";

import Questions from "../Questions/Questions";
import Quiz from "../Quiz/Quiz";
import Feedback from "../Feedback/Feedback";
import manuscriptImage from "../../images/Object/manuscript.svg";
import Advantages from "../Advantages/Advantages";
import LoadableImage from "../LoadableImage/LoadableImage";
import CustomVideoPlayer from "../CustomVideoPlayer/CustomVideoPlayer";

import openIcon from "../../images/Main/union.svg";
import Slider from "react-slick";

import useWindowSize from "../../hooks/useWindowSize";

import arrow from "../../images/Main/arrow.svg";
import {
  advantages,
  designPlans,
  factors,
  questionsAparts,
  sliderImages,
} from "../../utils/constants";

import { Accordion, Panel } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";

import video1 from "../../images/RealizedGallery/leontievsky/video.mp4";
import poster1 from "../../images/RealizedGallery/leontievsky/poster.png";

import video2 from "../../images/RealizedGallery/mirozdanie/video.mp4";
import poster2 from "../../images/RealizedGallery/mirozdanie/poster.png";

import { Helmet } from "react-helmet";

const Aparts = ({ handleOpenPopup, handleOpenPopupRequest }) => {
  const [activeDesignElement, setActiveDesignElement] = useState(0);
  const [currentSliderImages, setCurrentSliderImages] = useState(
    sliderImages.first
  );
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentSlideIndexes, setCurrentSlideIndexes] = useState([0, 0, 0, 0]);
  const [activeKey, setActiveKey] = useState(null);
  const [activeKeySecond, setActiveKeySecond] = useState(0);
  const [isSliderVisible, setIsSliderVisible] = useState(true);

  const size = useWindowSize();

  const sliderRef = useRef(null);
  const sliderRefSecond = useRef(null);
  const designContainerRef = useRef(null);

  const handleSelect = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const handleSelectSecond = (key) => {
    setActiveKeySecond(activeKeySecond === key ? null : key);
  };

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button
        className={`design__slider-button design__slider-button--prev`}
        onClick={onClick}
      >
        <img src={arrow} className="popup-image__arrow" alt="стрелка влево" />
      </button>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button
        className={`design__slider-button design__slider-button--next`}
        onClick={onClick}
      >
        <img src={arrow} className="popup-image__arrow" alt="стрелка вправо" />
      </button>
    );
  };

  let settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    arrows: true,
    focusOnSelect: true,
    centerPadding: "0",
    speed: 300,
    swipeToSlide: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => setCurrentSlideIndex(next),
  };

  let settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    arrows: true,
    focusOnSelect: true,
    centerPadding: "0",
    swipeToSlide: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => handleSlideChange(activeKeySecond, next),
  };

  const handleDesignElementClick = (index) => {
    if (index === activeDesignElement) return;
    setActiveDesignElement(index);
    setIsSliderVisible(false);

    setTimeout(() => {
      switch (index) {
        case 0:
          setCurrentSliderImages(sliderImages.first);
          break;
        case 1:
          setCurrentSliderImages(sliderImages.second);
          break;
        case 2:
          setCurrentSliderImages(sliderImages.third);
          break;
        case 3:
          setCurrentSliderImages(sliderImages.fourth);
          break;
        default:
          setCurrentSliderImages(sliderImages.first);
      }

      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
      setTimeout(() => {
        setIsSliderVisible(true);
      }, 300);
    }, 300);
  };

  const handleSlideChange = (index, slideIndex) => {
    setCurrentSlideIndexes((prev) => {
      const newIndexes = [...prev];
      newIndexes[index] = slideIndex;
      return newIndexes;
    });
  };

  return (
    <section className="aparts">
      <Helmet>
        <title>
          Заказать дизайн проект интерьера загородного дома с 3D-визуализацией в
          СПБ– СОДА
        </title>
        <meta
          name="description"
          content="Студия СОДА воплощает авторские, продуманные до мелочей, дизайн-проекты интерьера коттеджей и загородных домов в Санкт-Петербурге СОДА. Заказывая дизайн интерьера загородного дома у нас, вы получите полную с 3D-визуализацию и рабочую документацию по проекту."
        />
      </Helmet>
      <Promo
        title={"Дизайн загородных домов “под ключ”"}
        handleOpenPopup={handleOpenPopup}
      />
      <LoadableImage
        src={manuscriptImage}
        className="main__manuscript"
        alt="Рукопись «Ваше новое качество жизни»"
      />
      <div className="main__about main__about_type_aparts">
        <p className="main__text">
          Жизнь за городом привлекает своей тишиной, природной гармонией и
          обилием пространства. Однако именно грамотный дизайн интерьера
          загородного дома способен сделать дом уютным, стильным и комфортным
          для проживания. Студия СОДА предлагает создать уникальный и
          функциональный дизайн-проект дома, который полностью учитывает ваши
          предпочтения и специфику архитектуры вашего жилья.  
        </p>
        <p className="main__text">
          Мы работаем как над созданием нового дизайна интерьера, так и над
          преображением существующего, чтобы каждый уголок вашего дома отражал
          вашу индивидуальность. В нашем портфолио представлены самые разные
          проекты, выполненные в Санкт-Петербурге и за его пределами. Мы готовы
          реализовать даже самые смелые задумки.  
        </p>
      </div>
      <div className="service__question">
        <h2 className="service__question-title">Стоимость услуг</h2>
        <Accordion activeKey={activeKey} onSelect={handleSelect}>
          <Panel
            eventKey="0"
            className="questions__container"
            header={
              <div className="questions__title-container">
                <h3 className="question__question">
                  Дизайн-проект <br className="prices__wrap" /> от 5 000 руб. за
                  м²
                </h3>
                <button
                  type="button"
                  className={`button questions__button ${
                    activeKey === "0" ? "questions__button_active" : ""
                  }`}
                >
                  <img
                    src={openIcon}
                    className={`question__open-image ${
                      activeKey === "0" ? "questions__open-image_active" : ""
                    }`}
                    alt="Значок вверх"
                  />
                </button>
              </div>
            }
          >
            <div className="questions__answer questions__answer_active">
              <div className="service__answer">
                <p className="servise__answer-title">
                  Стоимость дизайн проекта дома зависит от уровня детализации и
                  проработки этапов. Мы предлагаем комплексный подход, который
                  включает:
                </p>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Анализ объекта</li>
                  <li className="policy__element servise__answer-text">
                    Осмотр загородного дома и изучение его архитектурных
                    особенностей, природного окружения и инженерных
                    коммуникаций. Это позволяет адаптировать будущий дизайн
                    интерьера пространства к вашим требованиям. 
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Обмерный план</li>
                  <li className="policy__element servise__answer-text">
                    Подробные обмеры всех помещений дома: размеры комнат, высота
                    потолков, расположение окон и дверных проемов. Это основа
                    для дальнейшей работы над дизайном интерьера. 
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">Техническое задание</li>
                  <li className="policy__element servise__answer-text">
                    Составление подробного списка ваших пожеланий. Вы указываете
                    предпочитаемый стиль интерьера, желательно ли использование
                    минимализма или любых других стилистических направлений,
                    требования к мебели и материалам.  
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Планировочное решение
                  </li>
                  <li className="policy__element servise__answer-text">
                    Разработка оптимальной планировки с учетом всех
                    функциональных зон дома. Пространства планируются так, чтобы
                    каждый член семьи чувствовал себя комфортно.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Согласование бюджета
                  </li>
                  <li className="policy__element servise__answer-text">
                    Согласование финансовой сметы дизайна интерьера коттеджа:
                    подбор отделочных материалов, мебели и декора в рамках
                    установленного бюджета.  
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">3D-визуализация</li>
                  <li className="policy__element servise__answer-text">
                    Реалистичные визуализации будущего интерьера. Это дает
                    возможность увидеть конечный результат до начала работы и
                    внести необходимые изменения.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Комплектация проекта
                  </li>
                  <li className="policy__element servise__answer-text">
                    Подбор мебели, отделочных материалов и предметов декора для
                    реализации дизайн-проекта дома. Мы создаем полный список
                    позиций с указанием стоимости, брендов и магазинов.
                  </li>
                </ul>
                <ul className="policy__list service__answer-container ">
                  <li className="servise__answer-title">
                    Рабочая документация для строителей
                  </li>
                  <li className="policy__element servise__answer-text">
                    Создание чертежей и детализированных планов для строителей.
                    Включает схемы размещения электрики, сантехники, планы
                    потолков и полов, а также рекомендации по выбору
                    материалов. 
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Accordion>
        <button
          className="button"
          style={{ alignSelf: "center" }}
          onClick={handleOpenPopup}
        >
          Заказать проект
        </button>
      </div>

      <Gallery gallery={galleryHouses} small={true} />
      <div className="object__container">
        <h2 className="object__title object__title_type_video">
          Видеообзоры выполненных объектов
        </h2>
        <div className="service__videos">
          <CustomVideoPlayer videoSrc={video1} poster={poster1} />
          <CustomVideoPlayer videoSrc={video2} poster={poster2} />
        </div>
        <a
          className="button gallery__link"
          href="https://vk.com/video/@penta_pro"
          target="_blank"
          rel="noreferrer"
        >
          Посмотреть другие видеообзоры
        </a>
      </div>
      <Scheme />
      <div className="design">
        <h2 className="design__title">Дизайн-проект</h2>
        <p className="design__text">
          Вы получите персональный проект интерьера со всей рабочей
          документацией
        </p>
        {size.width > 576 && (
          <div ref={designContainerRef} className="design__container">
            <div className="design__column">
              <div
                className={`design__element ${
                  activeDesignElement === 0 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(0)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 0 ? "design__number_active" : ""
                  }`}
                >
                  1
                </p>
                Планировочное решение
              </div>
              <div
                className={`design__element ${
                  activeDesignElement === 1 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(1)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 1 ? "design__number_active" : ""
                  }`}
                >
                  2
                </p>
                Технические чертежи
              </div>
              <div
                className={`design__element ${
                  activeDesignElement === 2 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(2)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 2 ? "design__number_active" : ""
                  }`}
                >
                  3
                </p>
                3D-визуализация
              </div>
              <div
                className={`design__element ${
                  activeDesignElement === 3 ? "design__element_active" : ""
                }`}
                onClick={() => handleDesignElementClick(3)}
              >
                <p
                  className={`design__number ${
                    activeDesignElement === 3 ? "design__number_active" : ""
                  }`}
                >
                  4
                </p>
                Спецификация
              </div>
            </div>
            <div
              className={`design__slider ${
                isSliderVisible ? "visible" : "hidden"
              }`}
            >
              <Slider className="plans" {...settings} ref={sliderRef}>
                {currentSliderImages.map((image) => (
                  <LoadableImage
                    src={image}
                    className="design__image"
                    alt="Схема"
                  />
                ))}
              </Slider>
              <p className="design__slider-index">
                {currentSlideIndex + 1}/{currentSliderImages.length}
              </p>
            </div>
          </div>
        )}
        {size.width < 576 && (
          <Accordion activeKey={activeKeySecond} onSelect={handleSelectSecond}>
            {designPlans.map((data, index) => (
              <Panel
                key={index}
                eventKey={index}
                header={
                  <div
                    className={`design__element ${
                      activeKeySecond === index ? "design__element_active" : ""
                    }`}
                  >
                    <p
                      className={`design__number ${
                        activeKeySecond === index ? "design__number_active" : ""
                      }`}
                    >
                      {data.number}
                    </p>
                    {data.title}
                    <button
                      type="button"
                      className={`button questions__button ${
                        activeKeySecond === index
                          ? "questions__button_active"
                          : ""
                      }`}
                    >
                      <img
                        src={openIcon}
                        className={`question__open-image ${
                          activeKeySecond === index
                            ? "questions__open-image_active"
                            : ""
                        }`}
                        alt="Значок вверх"
                      />
                    </button>
                  </div>
                }
              >
                <div className={`design__slider`}>
                  <Slider
                    className="plans"
                    {...settings2}
                    ref={sliderRefSecond}
                  >
                    {data.plans.map((image) => (
                      <LoadableImage
                        src={image}
                        className="design__image"
                        alt="Схема"
                      />
                    ))}
                  </Slider>
                  <p className="design__slider-index">
                    {currentSlideIndexes[activeKeySecond] + 1}/
                    {data.plans.length}
                  </p>
                </div>
              </Panel>
            ))}
          </Accordion>
        )}
      </div>
      <div className="factors">
        <h3 className="factors__title">
          9 факторов, от которых зависит цена на дизайн загородного дома
        </h3>
        <ul className="factors__list">
          {factors.map((element) => (
            <li className="factors__element" key={element.number}>
              <span className="factors__number">{element.number}</span>
              {element.factorAlt ? element.factorAlt : element.factor}
            </li>
          ))}
        </ul>
      </div>
      <div className="mon">
        <h2 className="service__question-title">Стили дизайна интерьера </h2>
        <p className="mon__text">
          Наши дизайнеры способны воссоздать практически любой стиль в дизайне
          интерьера загородного дома.
        </p>
        <div className="mon__container">
          <h4 className="mon__subtitle">Классический стиль</h4>
          <p className="mon__text">
            Классика – это вечное сочетание роскоши, гармонии и элегантности.
            Дизайн интерьера в этом стиле идеально подходит для загородных домов
            благодаря массивной мебели из дорогих материалов, колоннам, лепнине
            и мраморным элементам.
          </p>
          <p className="mon__text">Отличительные черты:</p>
          <ul className="mon__list">
            <li className="mon__point">
              Симметрия в расстановке мебели и декоративных элементов;
            </li>
            <li className="mon__point">
              Теплая палитра цветов: бежевый, золотой, терракотовый;
            </li>
            <li className="mon__point">
              Использование дорогих текстур и материалов.
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Минимализм</h4>
          <p className="mon__text">
            Это стиль, акцентирующий внимание на свободе пространства и
            функциональности. Он идеально вписывается в большие комнаты
            загородного дома, где нужно подчеркнуть простор и свет.
          </p>
          <p className="mon__text">Отличительные черты:</p>
          <ul className="mon__list">
            <li className="mon__point">Простые линии и лаконичные формы;</li>
            <li className="mon__point">
              Однотонные цвета: белый, серый, черный;
            </li>
            <li className="mon__point">
              Минимум декора, акцент на современные технологические решения.
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Прованс</h4>
          <p className="mon__text">
            Стиль навеян французской глубинкой: он объединяет романтику и
            естественность. Дизайн интерьера загородного дома в стиле Прованс
            очарует легкостью и уютом. 
          </p>
          <p className="mon__text">Отличительные черты:</p>
          <ul className="mon__list">
            <li className="mon__point">
              Преобладание пастельных оттенков (лаванда, мятный, розовый);
            </li>
            <li className="mon__point">
              Антикварная мебель с искусственным эффектом старения;
            </li>
            <li className="mon__point">
              Использование дерева, текстиля с цветочным рисунком и кованых
              элементов.
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Эко-стиль</h4>
          <p className="mon__text">
            Это выбор тех, кто ценит природу и хочет воплотить в интерьере
            гармонию с окружающим миром. Это органичное сочетание натуральных
            материалов и природных мотивов. 
          </p>
          <p className="mon__text">Отличительные черты:</p>
          <ul className="mon__list">
            <li className="mon__point">Использование дерева, камня, пробки;</li>
            <li className="mon__point">
              Много зелени в интерьере – комнатные растения становятся частью
              композиции;
            </li>
            <li className="mon__point">
              Цветовая палитра из натуральных оттенков: бежевый, зеленый,
              песочный.
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Скандинавский стиль</h4>
          <p className="mon__text">
            Он подойдет для тех, кто ценит уют, свет и функциональность.
            Оптимально вписывается в любые загородные дома и создает спокойное и
            лаконичное пространство.
          </p>
          <p className="mon__text">Отличительные черты:</p>
          <ul className="mon__list">
            <li className="mon__point">
              Светлые стены и натуральные материалы, такие как дерево и хлопок;
            </li>
            <li className="mon__point">
              Мебель с чистыми и минималистичными линиями;
            </li>
            <li className="mon__point">
              Декор с акцентами в виде текстиля и живой зелени;
            </li>
            <li className="mon__point">
              Практичные решения для организации пространства.
            </li>
          </ul>
        </div>
      </div>
      <Advantages advantages={advantages} />
      <Price />
      <Feedback />
      <Questions questions={questionsAparts} />
      <Quiz handleOpenPopupRequest={handleOpenPopupRequest} />
    </section>
  );
};

export default Aparts;
