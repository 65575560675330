import "./Footer.css";
import React from "react";

import Logo from "../Logo/Logo";

import telegramImage from "../../images/Footer/telegram.svg";
import whatsappImage from "../../images/Footer/whatsapp.svg";
import vkImage from "../../images/Footer/vk.svg";
import youtubeImage from "../../images/Footer/youtube.svg";
import instaImage from "../../images/Footer/insta.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__column footer__column_type_logo">
          <Logo />
          <div>
            <p className="footer__policy">© 2024 Сода</p>
            <p className="footer__policy">
              Отправляя любую форму, вы соглашаетесь c{" "}
              <a href="/policy" className="footer__policy-link">
                Политикой конфиденциальности
              </a>
            </p>
          </div>
        </div>
        <div className="footer__column footer__column_type_services">
          <h3 className="footer__title">Услуги</h3>
          <ul className="footer__list">
            <li className="footer__element">
              <a
                className="footer__link"
                href="/services/apartments"
                rel="noreferrer"
              >
                Дизайн квартир “под ключ”
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="/services/houses"
                rel="noreferrer"
              >
                Дизайн загородных домов “под ключ”
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="/services/contracting"
                rel="noreferrer"
              >
                Генеральный подряд
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="/services/supervision"
                rel="noreferrer"
              >
                Авторский надзор
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="/services/renovation"
                rel="noreferrer"
              >
                Ремонт квартир
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__column footer__column_type_gallery">
          <h3 className="footer__title">Галерея работ</h3>
          <ul className="footer__list">
            <li className="footer__element">
              <a
                className="footer__link"
                href="/gallery/realized"
                rel="noreferrer"
              >
                Реализованные объекты
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="/gallery/design"
                rel="noreferrer"
              >
                Дизайн интерьеров квартир
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__column footer__column_type_company">
          <h3 className="footer__title">О компании</h3>
          <ul className="footer__list">
            <li className="footer__element">
              <a className="footer__link" href="/price" rel="noreferrer">
                Цены
              </a>
            </li>
            <li className="footer__element">
              <a className="footer__link" href="/contacts" rel="noreferrer">
                Контакты
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__column footer__column_type_contacts">
          <h3 className="footer__title">Контакты</h3>
          <ul className="footer__list">
            <li className="footer__element">
              <p className="footer__text">Индекс 197101</p>
            </li>
            <li className="footer__element">
              <p className="footer__text">Санкт-Петербург</p>
            </li>
            <li className="footer__element">
              {/* prettier-ignore */}
              <pre className="footer__text">Большой проспект <br className="footer__wrap" />П.С., 65</pre>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="tel:89277485823"
                target="_blank"
                rel="noreferrer"
              >
                +7 (927) 748-58-23
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="mailto:soda@penta.pro"
                target="_blank"
                rel="noreferrer"
              >
                soda@penta.pro
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__column footer__column_type_socials">
          <h3 className="footer__title">Соц. сети</h3>
          <ul className="footer__list footer__list_type_high">
            <li className="footer__element">
              <a
                className="footer__link"
                href="https://t.me/PentaSpb"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={telegramImage}
                  className="footer__icon-image"
                  alt="Иконка соц. сети"
                />
                Telegram
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="https://wa.me/+79013166776"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={whatsappImage}
                  className="footer__icon-image"
                  alt="Иконка соц. сети"
                />
                WhatsApp
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="https://vk.com/penta_pro"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={vkImage}
                  className="footer__icon-image"
                  alt="Иконка соц. сети"
                />
                Вконтакте
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="https://www.youtube.com/channel/UCMZvRl2oYk_u9rNfnvCnkZA"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={youtubeImage}
                  className="footer__icon-image"
                  alt="Иконка соц. сети"
                />
                YouTube
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="https://www.instagram.com/penta.pro?igsh=ZmJhdDloamkxa3Jx"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={instaImage}
                  className="footer__icon-image"
                  alt="Иконка соц. сети"
                />
                Instagram
              </a>
            </li>
          </ul>
          <ul className="footer__list footer__list_type_small">
            <li className="footer__element">
              <a
                className="footer__link"
                href="https://t.me/PentaSpb"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={telegramImage}
                  className="footer__icon-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="https://wa.me/+79013166776"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={whatsappImage}
                  className="footer__icon-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="https://vk.com/penta_pro"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={vkImage}
                  className="footer__icon-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="https://www.youtube.com/channel/UCMZvRl2oYk_u9rNfnvCnkZA"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={youtubeImage}
                  className="footer__icon-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="footer__element">
              <a
                className="footer__link"
                href="https://www.instagram.com/penta.pro?igsh=ZmJhdDloamkxa3Jx"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={instaImage}
                  className="footer__icon-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
